import React from "react";
import Step from "../Components/Step";
import { Col, Row } from "antd";
import Cart from "../Components/Cart";
import TabWithoutTab from "./TabWithoutTab";

const SelectPersonolizationWithoutTab = () => {
  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="container">
        <Step active={3} />

        <Row>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <TabWithoutTab />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Cart />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SelectPersonolizationWithoutTab;
