import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addItemToCart, addRoomInCart, clearCart } from "../redux/cartSlice";

const ShowOffer = ( {hotelId} ) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  const offersData = [
    {
      id: "offer1",
      hotelId: "hotel1",
      img: "images/room-img-08.jpg",
      roomName: "Best Discounted rate (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 345,
    },
    {
      id: "offer2",
      hotelId: "hotel1",
      img: "images/room-img-09.jpg",
      roomName: "Member Discount (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 245,
    },
    {
      id: "offer3",
      hotelId: "hotel1",
      img: "images/room-img-10.jpg",
      roomName: "Stay + Package (Limited time only)",
      description:
        'Choose any one package with stay. Complementary airport transfer, spa, or dining experience. This offer is fully pre-paid and non-cancellable.',
      price: 345,
    },
    {
      id: "offer4",
      hotelId: "hotel2",
      img: "images/room-img-08.jpg",
      roomName: "Best Discounted rate (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 345,
    },
    {
      id: "offer5",
      hotelId: "hotel2",
      img: "images/room-img-09.jpg",
      roomName: "Member Discount (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 245,
    },
    {
      id: "offer6",
      hotelId: "hotel2",
      img: "images/room-img-10.jpg",
      roomName: "Stay + Package (Limited time only)",
      description:
        'Choose any one package with stay. Complementary airport transfer, spa, or dining experience. This offer is fully pre-paid and non-cancellable.',
      price: 345,
    },
    {
      id: "offer7",
      hotelId: "hotel3",
      img: "images/room-img-08.jpg",
      roomName: "Best Discounted rate (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 345,
    },
    {
      id: "offer8",
      hotelId: "hotel3",
      img: "images/room-img-09.jpg",
      roomName: "Member Discount (Limited time only)",
      description:
        'Plan ahead and save! Book your stay 14 days or more in advance and save up to 30% at our Hotes. this offer is fully pre-paid and non-cancelable',
      price: 245,
    },
    {
      id: "offer9",
      hotelId: "hotel3",
      img: "images/room-img-10.jpg",
      roomName: "Stay + Package (Limited time only)",
      description:
        'Choose any one package with stay. Complementary airport transfer, spa, or dining experience. This offer is fully pre-paid and non-cancellable.',
      price: 345,
    },
  ];

  const filteredRooms = offersData.filter((room) => room.hotelId === hotelId);

  
    // Add Item In Cart
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { destination, date, roomAndGuest } = useSelector(
      (state) => state.searchHotel
    );
  
    const addToCart = (item) => {
      dispatch(
        addRoomInCart({
          id: item.id,
          hotelId: item.hotelId,
          type : 'offer',
          name: item.roomName,
          checkIn: date.checkIn,
          checkOut: date.checkOut,
          guests: roomAndGuest.adults,
          price: item.price,
          nights: date.nights,
          totalPrice: item.price * date.nights,
        })
      );
    dispatch(clearCart());
      navigate("/personalization");
    };
  

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      itemClass="show-offer"
    >
      {filteredRooms.map((item, index) => (
        <div key={index} className="room-item" style={{ padding: "0 15px" }}>
          <div className="room-image">
            <img
              src={`${process.env.PUBLIC_URL}/${item.img}`}
              alt="Hotel"
              className="room-img"
            />
          </div>
          <div className="room-details">
            <h3 className="room-quality">{item.roomName}</h3>
            <p className="room-description">{item.description}</p>
            <span className="room-rate">
              Standard Rate ${item.price}.00 / night
            </span>
            <div className="room-rate-footer">
              <span className="room-price">${item.price}</span>
              <button className="btn-select" onClick={() => addToCart(item)} >Select</button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default ShowOffer;
