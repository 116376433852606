import React from "react";
import Step from "../Components/Step";
import SearchForm from "../Components/SearchForm";
import HotelListSingle from "./HotelListSingle";

const SelectHotelSingle = () => {
  return (
    <div>
      <div className="container">
        <Step active={2} />
        <SearchForm />
        <HotelListSingle />
      
      </div>
    </div>
  );
};

export default SelectHotelSingle;
