import { Layout } from 'antd';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from './Components/PrivateRoute';
import AppHeader from './Components/AppHeader';
import AppFooter from './Components/AppFooter';
import SelectDate from './pages/SelectDate';
import SelectHotel from './pages/SelectHotel';
import SelectPersonolization from './pages/SelectPersonolization';
import Confirmation from './pages/Confirmation';
import Login from './auth/Login';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { setHotels } from './redux/hotelSlice';
import axios from 'axios';
import { useEffect } from 'react';
import { logout } from './redux/authSlice';
import ScrollToTop from './Components/ScrollToTop';
import SelectDateSingle from './singleHotel/SelectDateSingle';
import SelectHotelSingle from './singleHotel/SelectHotelSingle';
import SelectPersonolizationWithoutTab from './singleHotel/SelectPersonolizationWithoutTab';

const { Header, Content, Footer} = Layout;

function App() {
  const API_BASE_URL = "https://www.hotelapps.cloud/api";
  const dispatch = useDispatch();

  const fetchHotels = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getHotels.php`);
      const hotelData = response.data.data;
      dispatch(setHotels(hotelData))

    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchHotels();
  }, []);


  // Call In Page Reload to Login 
  const userId = useSelector((state) => state.auth.userId);

  const callOnReload = () => {
    if (userId === null) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    window.onload = callOnReload;
  }, []);



  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Layout>
          <Header className='header'>
            <AppHeader />
          </Header>
          <Content style={{backgroundColor:'#fff'}}>
            <Routes>
              <Route path="/" element={<SelectDate />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />

              <Route path="/hotels" element={<PrivateRoute> <SelectHotel /> </PrivateRoute>} />
              <Route path="/personalization" element={<PrivateRoute> <SelectPersonolization /> </PrivateRoute>} />
              <Route path="/confirmation" element={<PrivateRoute> <Confirmation /> </PrivateRoute>} />

              <Route path="/singleHotel/search" element={<SelectDateSingle />} />
              <Route path="/singleHotel/hotels" element={<SelectHotelSingle />} />
              <Route path="/withoutTab/personalization" element={<SelectPersonolizationWithoutTab />} />

            </Routes>
          </Content>
          <Footer className='footer'>
              <AppFooter />
          </Footer>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
