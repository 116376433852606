import { faBars, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Drawer, Menu, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/authSlice";

const AppHeader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const isAuthenticate = useSelector((state) => state.auth.isAuthenticate);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <Col>
          <div className="logo">
            <Link to={"/"}>
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
            </Link>
          </div>
        </Col>
        <Col flex="auto" className="desktop-menu navbar-menu">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            overflowedIndicator={null}
          >
            {/* <Menu.Item key="1">
              <Link to={"/"}>Home</Link>
            </Menu.Item> */}
            <Menu.SubMenu key={"1"} title="Home">
              <Menu.Item key="11">
                <Link to={"/singleHotel/search"}>Single Hotel</Link>
              </Menu.Item>
              <Menu.Item key="12">
                <Link to={"/"}>Multiple Hotels</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key={"2"} title="Hotels">
              <Menu.Item key="21">
                <Link to={"/singleHotel/hotels"}>Single Hotel</Link>
              </Menu.Item>
              <Menu.Item key="22">
                <Link to={"/hotels"}>Multiple Hotels</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key={"3"} title="Experiences">
              <Menu.Item key="31">
                <Link to={"/personalization"}>With Tab</Link>
              </Menu.Item>
              <Menu.Item key="32">
                <Link to={"/withoutTab/personalization"}>Without Tab</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="4">About</Menu.Item>
            <Menu.Item key="5">Services</Menu.Item>
            <Menu.Item key="6">Offers</Menu.Item>

            {isAuthenticate ? (
              <Menu.SubMenu key={"7"} title="Profile">
                <Menu.Item key="71">
                  <Link onClick={handleLogout}>Logout</Link>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              <Menu.SubMenu key={"8"} title="Signin or Join">
                <Menu.Item key="81">
                  <Link to={"/login"}>Sign in</Link>
                </Menu.Item>
                <Menu.Item key="82">
                  <Link to={"/register"}>Sign Up</Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}

            {/* <Menu.Item key="8">
              <Link to={"/personalization"}>
                <FontAwesomeIcon icon={faCartShopping} />
              </Link>
            </Menu.Item> */}
          </Menu>
        </Col>

        <Col className="mobile-menu">
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faBars} />}
            onClick={toggleDrawer}
          />
        </Col>
      </Row>

      <Drawer
        title="Menu"
        placement="right"
        onClose={toggleDrawer}
        visible={drawerVisible}
        className="mobile-sidebar-menu"
      >
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to={"/"}>Home</Link>
          </Menu.Item>
          <Menu.Item key="2">Hotels</Menu.Item>
          <Menu.Item key="3">About</Menu.Item>
          <Menu.Item key="4">Services</Menu.Item>
          <Menu.Item key="5">Offers</Menu.Item>

          {isAuthenticate ? (
            <Menu.SubMenu key={"6"} title="Profile">
              <Menu.Item key="61">
                <Link onClick={handleLogout}>Logout</Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : (
            <Menu.SubMenu key={"7"} title="Signin or Join">
              <Menu.Item key="71">
                <Link to={"/login"}>Sign in</Link>
              </Menu.Item>
              <Menu.Item key="72">
                <Link to={"/register"}>Sign Up</Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

export default AppHeader;
