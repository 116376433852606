import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import Dining from "./tabData/Dining";
import Wellness from "./tabData/Wellness";
import Transportation from "./tabData/Transportation";
import Occasion from "./tabData/Occasion";
import Experiences from "./tabData/Experiences";
import Perks from "./tabData/Perks";
import EgiftShop from "./tabData/EgiftShop";

const tabs = [
  { name: "Dining", icon: "images/tab-icon-01.png" },
  { name: "Wellness", icon: "images/tab-icon-03.png" },
  { name: "Transportation", icon: "images/tab-icon-02.png" },
  { name: "Occasion", icon: "images/tab-icon-04.png" },
  {
    name: "More",
    moreTabs: [
      { name: "Experiences", icon: "images/tab-icon-08.png" },
      { name: "eGift Shop", icon: "images/tab-icon-05.png" },
      { name: "Perks", icon: "images/tab-icon-06.png" },
    ],
  },
];

const Tab = () => {
  const [activeTab, setActiveTab] = useState("Dining");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleMoreTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const moreMenu = (
    <Menu>
      {tabs
        .find((tab) => tab.name === "More")
        .moreTabs.map((item) => (
          <Menu.Item
            key={item.name}
            onClick={() => handleMoreTabClick(item.name)}
            className={`personalization-tab-item ${
              activeTab === item.name ? "active" : ""
            }`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/${item.icon}`}
              alt={`${item.name}-icon`}
              style={{ marginRight: 8 }}
            />
            {item.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="personalization-tab">
      <ul className="personalization-tab-list">
        {tabs.slice(0, 4).map((tab) => (
          <li
            key={tab.name}
            className={`personalization-tab-item ${
              activeTab === tab.name ? "active" : ""
            }`}
            onClick={() => handleTabClick(tab.name)}
          >
            <img src={`${process.env.PUBLIC_URL}/${tab.icon}`} alt="icon" />
            <span>{tab.name}</span>
          </li>
        ))}
        <Dropdown overlay={moreMenu} trigger={["click"]}>
          <li
            className={`personalization-tab-item more-tab ${
              activeTab &&
              tabs.find((tab) =>
                tab.moreTabs?.some((t) => t.name === activeTab)
              )
                ? "active"
                : ""
            }`}
          >
            <span>More</span> <DownOutlined />
          </li>
        </Dropdown>
      </ul>

      <div className="tab-content">
        {activeTab === "Dining" && <Dining />}
        {activeTab === "Wellness" && <Wellness />}
        {activeTab === "Transportation" && <Transportation />}
        {activeTab === "Occasion" && <Occasion />}
        {activeTab === "Experiences" && <Experiences />}
        {activeTab === "Perks" && <Perks />}
        {activeTab === "eGift Shop" && <EgiftShop />}
        
        {/* {tabs
          .find((tab) => tab.name === "More")
          ?.moreTabs.map(
            (moreTab) =>
              activeTab === moreTab.name && (
                <div key={moreTab.name}>
                  {moreTab.name}
                  <Perks />
                  <EgiftShop />
                  
                </div>
              )
          )} */}
      </div>
    </div>
  );
};

export default Tab;
