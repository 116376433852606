import React from "react";
import Step from "../Components/Step";
import SearchFormSingle from "./SearchFormSingle";

const SelectDateSingle = () => {
  return (
    <div className="container">
      <div className='row'>
        <div className="col-12">
          <Step active={1} />
          <SearchFormSingle />
         </div>
      </div>
    </div>
  );
};

export default SelectDateSingle;
