import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, Form, TimePicker } from "antd";
import React, { useState } from "react";
import { addItemToCart } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Select } from "antd";
import moment from "moment";

const { Option } = Select;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1199, min: 992 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const Occasion = () => {
  const [itemStates, setItemStates] = useState({});
  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.searchHotel.date);


  const data = [
    {
      id: "01",
      hotelId: "hotel1",
      title: "Reserve a Meeting Space 1",
      image: "./tab/occasion-1.jpg",
      content: "10% discount to book now this meeting space for your event. Request for any f&b or catering purchases.   ",
      hours: true,
      Experience: [
        {
          id: 1,
          value: "Extra space for family",
        },
        {
          id: 2,
          value: "Bachelor/Ette Party",
        },
        {
            id: 2,
            value: "Religious",
          },
          {
            id: 2,
            value: "Host gathering ",
          },
          {
            id: 2,
            value: "Product Activation ",
          },
          {
            id: 2,
            value: "Private Dining ",
          },
      ],
      // Experience2: [
      //   {
      //     id: 1,
      //     value: "Rustic yoga retreat",
      //     price: 100,
      //   },
      //   {
      //     id: 2,
      //     value: "Sea mud mask ",
      //     price: 230,
      //   },
      // ],
    //   payment: ["Pay Now", "Later"],
    },
    {
        id: "02",
        hotelId: "hotel1",
        title: "Reserve a Meeting Space 2",
        image: "./tab/occasion-2.jpg",
        content: "10% discount to book now this meeting space for your event. Request for any f&b or catering purchases.   ",
        hours: true,
        Experience: [
          {
            id: 1,
            value: "Extra space for family",
          },
          {
            id: 2,
            value: "Bachelor/Ette Party",
          },
          {
              id: 2,
              value: "Religious",
            },
            {
              id: 3,
              value: "Host gathering ",
            },
            {
              id: 4,
              value: "Product Activation ",
            },
            {
              id: 5,
              value: "Private Dining ",
            },
      ],
    //   payment: ["Pay Now", "Later"],
    },
  ];

  const addToCart = (item) => {
    const { date, time, Experience } = itemStates[item.id] || {};
    if (date && time) {
      dispatch(
        addItemToCart({
          id: item.id,
          hotelId: item.hotelId,
          name: item.title,
          price: Experience?.price,
          totalPrice: Experience?.price,
          date,
          time,
          Experience: Experience?.value,
        })
      );
    } else {
      alert("Select all options");
    }
  };

  const handleDateChange = (id, date, dateString) => {
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        date: dateString,
      },
    }));
  };

  const handleTimeChange = (id, time, timeString) => {
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        time: timeString,
      },
    }));
  };

  const handleSelectChange = (itemId, value, price) => {
    setItemStates((prev) => ({
      ...prev,
      [itemId]: {
        ...(prev[itemId] || {}),
        Experience: { value, price },
      },
    }));
  };

  // const handleSelectChange2 = (itemId, value, price) => {
  //   setItemStates((prev) => ({
  //     ...prev,
  //     [itemId]: {
  //       ...(prev[itemId] || {}),
  //       Experience2: { value, price },
  //     },
  //   }));
  // };

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      autoPlay={false}
      customTransition="transform 0.7s ease-in-out"
      autoPlaySpeed={3000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="carousel-container"
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      itemClass="Occasion"
    >
      {data.map((item) => (
        <div key={item.id} className="room-item" style={{ padding: "0 7px" }}>
          <div className="room-image">
          <img
              src={`${process.env.PUBLIC_URL}/${item.image}`}
              alt="Hotel"
              className="room-img"
            />
          </div>
          <div className="room-details">
            <h3 className="room-quality">{item.title}</h3>
            <p className="room-description">{item.content}</p>
            <div className="select-date-time-option">
              <div className="select-date">
                <label>Select Date</label>
                <DatePicker
                disabledDate={(current) => {
                  const checkIn = moment(selectedDate.checkIn, "DD-MM-YYYY");
                  const checkOut = moment(selectedDate.checkOut, "DD-MM-YYYY");
                  return current && (current < checkIn || current > checkOut);
                }}
                  onChange={(date, dateString) =>
                    handleDateChange(item.id, date, dateString)
                  }
                />
              </div>
              <div className="select-time">
                <label>Select Time</label>
                <Form.Item name="time-picker">
                  <TimePicker
                    onChange={(time, timeString) =>
                      handleTimeChange(item.id, time, timeString)
                    }
                  />
                </Form.Item>
              </div>
            </div>

            <Select
              placeholder="Select an option"
              style={{ width: "100%" }}
              onChange={(value) =>
                handleSelectChange(
                  item.id,
                  value,
                  item.Experience.find((option) => option.value === value)
                    ?.price
                )
              }
              value={itemStates[item.id]?.occasion?.value || undefined}
            >
              {item.Experience.map((option) => (
                <Option key={option.id} value={option.value}>
                  <span>{option.value}</span>
                  {option.price && <span> - ${option.price}</span>}
                </Option>
              ))}
            </Select>

            {/* {item.Experience2 && (
              <Select
                placeholder="Select an option"
                style={{ width: "100%" }}
                onChange={(value) =>
                  handleSelectChange2(
                    item.id,
                    value,
                    item.Experience2.find((option) => option.value === value)
                      ?.price
                  )
                }
                value={itemStates[item.id]?.occasion?.value || undefined}
              >
                {item.Experience2.map((option) => (
                  <Option key={option.id} value={option.value}>
                    <span>{option.value}</span>
                    {option.price && <span> - ${option.price}</span>}
                  </Option>
                ))}
              </Select>
            )} */}

            <div className="room-rate-footer">
              <button onClick={() => addToCart(item)} className="btn-add-stay">
                <span className="hide-text">Add to My Stay</span>{" "}
                <PlusOutlined />
              </button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Occasion;
