import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Col, Rate, Row } from "antd";
import {
  EnvironmentOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";

const RoomDetails = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="hotel-room-details">
      <Row gutter={16} className="hotel-room-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3 className="room-details-header">
            King Suite, Suite, 1 King, Sofa bed, Trundle bed
          </h3>
          <div className="room-area-info">
            <span>Sleep 3</span> | <span>1 King bed</span> |{" "}
            <span>1 King bed</span>
          </div>
          <ul className="hotel-room-amenities">
            <li>Separate tub and shower</li>
            <li>Air conditioning</li>
            <li>Cable television</li>
            <li>Mini-refrigerator</li>
            <li>Daily maid service</li>
            <li>Luxury linen type</li>
            <li>Duvet</li>
            <li>Bathroom amenities</li>
            <li>Adjoining rooms</li>
            <li>
              High Definition (HD) Flat Panel Television - 32 inches or greater
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="room-images-gallery">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              itemClass="show-offer"
            >
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/hotel-img-01.jpg`} alt="Hotel" width="100%" />
              </div>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/hotel-img-02.jpg`} alt="Hotel" width="100%" />
              </div>
            </Carousel>
          </div>
        </Col>
      </Row>
      <Row gutter={16} className="hotel-room-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Pool and Garden View Junior Suite</h3>
          <p>
            Pool and Garden View Junior Suite 635 sq ft. Showcasing sparkling
            pool and lush garden views these spacious suites feature a king bed
            with premium bedding duvets and a sofa bed. Guest bathrooms
            feature rainfall showers and premium amenities. Each suite boasts a
            media and technology package including flat-screen TV and free
            Wi-Fi. Amenities include an espresso machine with tea and coffee,
            yoga mats, iron and ironing board, hair dryer, an electronic laptop
            sized-safe, a mini fridge with purified water, daily housekeeping
            and turn-down service.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default RoomDetails;
