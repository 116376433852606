import React, { useState } from "react";
import { Button, Card, Col, Rate, Row, Drawer, Modal } from "antd";
import { EnvironmentOutlined, PlusCircleOutlined } from "@ant-design/icons";
import HotelDetails from "../Components/HotelDetails";
import ShowUpgrades from "../Components/ShowUpgrades";
import ShowOffer from "../Components/ShowOffer";
import ShowRoom from "../Components/ShowRoom";

const HotelListSingle = () => {
  const [activeHotelId, setActiveHotelId] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null); // Store selected hotel

  const hotelsData = [
    {
      id: "hotel1",
      img: "images/hotel-img-01.jpg",
      hotelname: "Palladium Hotel",
      rating: 4.2,
      review: 273,
      destination: 0,
      description:
        "Just off I-4 in Winter Park close to Park Avenue, Hannibal, Rollins College.",
      availability: "Sold Out",
      discount: "40%",
      price: 100,
    },
  ];

  const toggleDrawer = (id, section) => {
    setActiveHotelId(id);
    setActiveSection(section);
    setDrawerVisible(true); // Show the drawer
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setActiveHotelId(null);
    setActiveSection("");
  };

  const showModal = (hotel) => {
    setSelectedHotel(hotel); // Set the selected hotel data
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderDrawerContent = () => {
    if (activeSection === "rooms") {
      return <ShowRoom hotelId={activeHotelId} />;
    } else if (activeSection === "offers") {
      return <ShowOffer hotelId={activeHotelId} />;
    } else if (activeSection === "upgrades") {
      return <ShowUpgrades hotelId={activeHotelId} />;
    }
    return null;
  };

  return (
    <div className="hotels_list">
      {hotelsData.map((item, index) => (
        <>
          <div key={index}>
            <Card className="hotel-card">
              <Row>
                {/* Image Section */}
                <Col xs={24} sm={24} md={9} lg={9} xl={10}>
                  <div className="image-container">
                    <img
                      src={`${process.env.PUBLIC_URL}/${item.img}`}
                      alt="Hotel"
                      className="hotel-image"
                    />
                  </div>
                </Col>

                {/* Details Section */}
                <Col
                  xs={24}
                  sm={24}
                  md={15}
                  lg={15}
                  xl={14}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="hotel-content">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={16} xl={15}>
                        <h2
                          style={{ cursor: "pointer" }}
                          onClick={() => showModal(item)}
                        >
                          {item.hotelname}
                        </h2>{" "}
                        {/* Pass item here */}
                        <div className="rating">
                          <Rate allowHalf defaultValue={item.rating} />{" "}
                          <span className="review-text">
                            ({item.review} reviews)
                          </span>
                          <span className="distance">
                            <EnvironmentOutlined /> {item.destination} mi from
                            destination
                          </span>
                        </div>
                        <p className="hotel-description">{item.description}</p>
                        <div className="room-tag">
                          <span className="availability-label">Sold Out</span>
                        </div>
                        <div className="price-discount">
                          <p>
                            Last Minute Deal Save up to <b>{item.discount}</b>
                          </p>
                        </div>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1} xl={2}></Col>
                      <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                        <div className="price">
                          <span>From</span>
                          <span className="rate-price-per-night">
                            ${item.price}
                          </span>
                          <span>USD / Night</span>
                          <p>
                            Excluding Taxes &<br></br> Fees
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{display:'flex', justifyContent:'center'}} >
                <div className="buttons" style={{display:'flex', gap:'40px'}} >
                  <Button
                    className={`action-btn btn-outline ${
                      activeHotelId === item.id && activeSection === "rooms"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleDrawer(item.id, "rooms")}
                  >
                    Show Rooms <PlusCircleOutlined />
                  </Button>
                  <Button
                    className={`action-btn btn-outline ${
                      activeHotelId === item.id && activeSection === "offers"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleDrawer(item.id, "offers")}
                  >
                    Show Offers <PlusCircleOutlined />
                  </Button>
                  <Button
                    className={`action-btn btn-primary ${
                      activeHotelId === item.id && activeSection === "upgrades"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleDrawer(item.id, "upgrades")}
                  >
                    Show Upgrades <PlusCircleOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ))}

      {/* Drawer Component */}
      <Drawer
        title={
          activeSection === "rooms"
            ? "Available Rooms"
            : activeSection === "offers"
            ? "Hotel Offers"
            : "Available Upgrades"
        }
        placement="bottom"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
        height={"80%"}
      >
        {renderDrawerContent()}
      </Drawer>

      <Modal
        title={selectedHotel ? selectedHotel.hotelname : "Hotel Details"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <button
            onClick={handleCancel}
            style={{
              padding: "8px 16px",
              background: "#1890ff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        }
        width={1000}
      >
        {selectedHotel && (
          <>
            <HotelDetails selectedHotel={selectedHotel} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default HotelListSingle;
