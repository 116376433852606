import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import { addItemToCart } from "../redux/cartSlice";

const { Option } = Select;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1199, min: 992 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const TabWithoutTab = () => {
  const [itemStates, setItemStates] = useState({});
  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.searchHotel.date);

  const data = [
    {
      id: "01",
      hotelId: "hotel1",
      title: "Restaurant 1",
      image: "./tab/dining-1.jpg",
      content:
        "Asian fusion restaurant at HRH Nevada, Las Vegas that uses fresh ingredients and poolside seating. ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Birthday",
        },
        {
          id: 2,
          value: "Anniversary ",
        },
        {
          id: 3,
          value: "Family reunion  ",
        },
        {
          id: 4,
          value: "Graduation ",
        },
      ],
      Specials: [
        {
          id: 1,
          value: "Beer Pairing with Burger  ",
          price: 50,
        },
        {
          id: 2,
          value: "Veggie platter  ",
          price: 70,
        },
        {
          id: 3,
          value: "Sweet Tooth Delight",
          price: 20,
        },
      ],
    },
    {
      id: "02",
      hotelId: "hotel1",
      title: "Group Fitness",
      image: "./tab/wellness-3.jpg",
      content:
        "10% discount to book now one of our wellness packages. Advance purchase or book an appointment. ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Bootcamp",
        },
        {
          id: 2,
          value: "Yoga",
        },
        {
          id: 3,
          value: "Circuit",
        },
      ],
      payment: ["Pay Now", "Later"],
    },
    {
      id: "03",
      hotelId: "hotel1",
      title: "Car Rental Service  ",
      image: "./tab/transport-2.jpg",
      content:
        "10% discount to book now one of our Car Rental services. Advance purchase or book a time.  ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Budget",
          price: 120,
        },
        {
          id: 2,
          value: "Enterprise",
          price: 450,
        },
      ],
    },

    {
        id: "04",
        hotelId: "hotel1",
        title: "Restaurant 2",
        image: "./tab/dining-2.jpg",
        content: "Asian fusion restaurant at HRH Nevada, Las Vegas that uses fresh ingredients and poolside seating. ",
        hours: true,
        Occasion: [
          {
            id: 1,
            value: "Birthday",
          },
          {
            id: 2,
            value: "Anniversary ",
          },
          {
            id: 3,
            value: "Family reunion  ",
          },
          {
            id: 4,
            value: "Graduation ",
          },
        ],
        Specials: [
          {
            id: 1,
            value: "Beer Pairing with Burger  ",
            price: 50,
          },
          {
            id: 2,
            value: "Veggie platter  ",
            price: 70,
          },
          {
            id: 3,
            value: "Sweet Tooth Delight",
            price: 20,
          },
        ],
      },
  ];

  const addToCart = (item) => {
    const { date, time, occasion, specials } = itemStates[item.id] || {};
    if (date && time) {
      dispatch(
        addItemToCart({
          id: item.id,
          hotelId: item.hotelId,
          name: item.title,
          price: specials?.price || 0,
          totalPrice: specials?.price || 0,
          date,
          time,
          occasion: occasion?.value || null,
          specials: specials?.value || null,
        })
      );
    } else {
      alert("Please select all required options.");
    }
  };

  const handleStateChange = (id, field, value) => {
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        [field]: value,
      },
    }));
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="personalization-tab">
      <div className="tab-content">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={false}
          customTransition="transform 0.7s ease-in-out"
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="dining"
        >
          {data.map((item) => (
            <div
              key={item.id}
              className="room-item"
              style={{ padding: "0 7px" }}
            >
              <div className="room-image">
                <img
                  src={`${process.env.PUBLIC_URL}/${item.image}`}
                  alt="Hotel"
                  className="room-img"
                />
              </div>
              <div className="room-details">
                <h3 className="room-quality">{item.title}</h3>
                <p className="room-description">{item.content}</p>
                <div className="select-date-time-option">
                  <div className="select-date">
                    <label>Select Date</label>
                    <DatePicker
                      disabledDate={(current) => {
                        const checkIn = moment(
                          selectedDate.checkIn,
                          "DD-MM-YYYY"
                        );
                        const checkOut = moment(
                          selectedDate.checkOut,
                          "DD-MM-YYYY"
                        );
                        return (
                          current && (current < checkIn || current > checkOut)
                        );
                      }}
                      onChange={(date, dateString) =>
                        handleStateChange(item.id, "date", dateString)
                      }
                    />
                  </div>
                  <div className="select-time">
                    <label>Select Time</label>
                    <Form.Item name="time-picker">
                      <TimePicker
                        onChange={(time, timeString) =>
                          handleStateChange(item.id, "time", timeString)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                {item.Occasion && (
                  <Select
                    placeholder="Select Occasion"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleStateChange(
                        item.id,
                        "occasion",
                        item.Occasion.find((opt) => opt.value === value)
                      )
                    }
                    value={itemStates[item.id]?.occasion?.value || undefined}
                  >
                    {item.Occasion.map((option) => (
                      <Option key={option.id} value={option.value}>
                        {option.value}
                      </Option>
                    ))}
                  </Select>
                )}

                {item.Specials && (
                  <Select
                    placeholder="Select Special"
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={(value) =>
                      handleStateChange(
                        item.id,
                        "specials",
                        item.Specials.find((opt) => opt.value === value)
                      )
                    }
                    value={itemStates[item.id]?.specials?.value || undefined}
                  >
                    {item.Specials.map((option) => (
                      <Option key={option.id} value={option.value}>
                        {option.value} - ${option.price}
                      </Option>
                    ))}
                  </Select>
                )}

                {item.payment && (
                  <div className="radio-btn">
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>Pay Now</Radio>
                      <Radio value={2}>Later</Radio>
                    </Radio.Group>
                  </div>
                )}

                <div className="room-rate-footer">
                  <button
                    onClick={() => addToCart(item)}
                    className="btn-add-stay"
                  >
                    <span className="hide-text">Add to My Stay</span>{" "}
                    <PlusOutlined />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TabWithoutTab;
