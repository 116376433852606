import React, { useState } from "react";
import { Button, Row, Col, Dropdown, InputNumber } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import SelectDate from "../Components/SelectDate";
import RoomAndGuest from "../Components/RoomAndGuest";
import { useSelector } from "react-redux";
import Destination from "../Components/Destination";

const SearchFormSingle = () => {
  const navigate = useNavigate();
  const { destination, date, roomAndGuest } = useSelector(
    (state) => state.searchHotel
  );

  const handleSearch = () => {
    if (date.checkIn && date.checkOut && roomAndGuest.rooms) {
      navigate("/singleHotel/hotels");
    } else {
      alert("Please fill in all fields before searching.");
    }
  };

  return (
    <div className="ptb-3 search_form">
      <Row
        justify="center"
        style={{
          padding: "20px",
          border: "1px solid #eaeaea",
          borderRadius: "8px",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Col xs={24} sm={24} md={12} lg={6} xl={5}>
          <RoomAndGuest />
        </Col>

        <Col xs={24} sm={24} md={12} lg={7} xl={7}>
          <SelectDate />
        </Col>

        <Col className="continue-btn" xs={24} sm={24} md={12} lg={4} xl={5} style={{display:'flex', justifyContent:'end'}}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            className="custom-button"
            onClick={handleSearch}
          >
            CONTINUE
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchFormSingle;
